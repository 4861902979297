import React from "react"
import { IProduct } from "../../libs/interfaces"

function ProductCard({ product }: { product: IProduct }) {
  return (
    <div
      className="productContainer max-h-[300px] -mt-20 md:-mt-8 transition-all duration-500 ease-in-out hover:-translate-y-2 "
      key={product.name}
    >
      <div className="productCardBg md-productCardBg absolute z--1 bg-primary-600 dark:bg-primary-300 skew-x-[10deg]"></div>
      <div className="productCard md-productCard relative bg-white p-1 dark:bg-slate-700 -skew-x-[10deg]">
        <div className="content">
          <h5 className="title h5 mt-4 text-base md:text-lg font-medium text-center hover:text-primary-600 skew-x-[10deg]">
            {product.name}
          </h5>
          <p className="mt-4 px-4 text-sm md:text-base text-slate-500 text-center dark:text-slate-300 skew-x-[10deg]">
            {product.description}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
