import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import ProductsContainer from "../components/Products/ProductsContainer"
import ProductsHeader from "../components/Products/ProductsHeader"
import Seo from "../components/Seo"
import { IProduct } from "../libs/interfaces"

function Products({ data }: any) {
  const { title, subtitle, header_img } = data.static.frontmatter

  const seo = {
    metaTitle: title,
    metaDescription: subtitle,
  }

  const products: IProduct[] = [
    ...data.products.nodes.map((node: any) => ({
      ...node.frontmatter,
      id: node.id,
      logo_dark: data.logos.nodes.find(
        (el: any) => el.name == node.frontmatter.logo_dark,
      ),
      logo_light: data.logos.nodes.find(
        (el: any) => el.name == node.frontmatter.logo_light,
      ),
    })),
  ]

  const header_img_data = data.staticImg.nodes.find((el: any) => {
    return header_img == el.name
  })?.childrenImageSharp[0]

  return (
    <Layout>
      <Seo seo={seo} lang={data.locales.edges[0].node.language}></Seo>
      <ProductsHeader
        title={title}
        subtitle={subtitle}
        header_img={header_img_data}
      />
      <ProductsContainer products={products} />
    </Layout>
  )
}

export default Products

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        static: markdownRemark(
            frontmatter: { slug: { eq: "products/static" }, lang: { eq: $language } }
        ) {
            frontmatter {
                title
                subtitle
                header_img
            }
        }
        staticImg: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                relativePath: { glob: "assets/products/*" }
            }
        ) {
            nodes {
                name
                childrenImageSharp {
                    gatsbyImageData
                }
            }
        }
        logos: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
                relativePath: { glob: "assets/logos/*" }
            }
        ) {
            nodes {
                name
                childrenImageSharp {
                    gatsbyImageData
                }
                relativePath
                extension
            }
        }
        products: allMarkdownRemark(
            filter: {
                frontmatter: { slug: { glob: "products/*/*" }, lang: { eq: $language } }
            }
        ) {
            nodes {
                html
                id
                frontmatter {
                    slug
                    name
                    description
                    on_homepage
                    logo_light
                    logo_dark
                }
            }
        }
    }
`
