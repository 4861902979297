import React, { useEffect } from "react"
import { IProduct } from "../../libs/interfaces"
import { Trans } from "react-i18next"
import ProductCard from "./ProductCard"
import { setupSlider } from "../../services/setupSlider"

function ProductsContainer({ products, withSlider }: { products: IProduct[], withSlider: boolean }) {

  if (withSlider) {
    useEffect(() => {
      setTimeout(() => {
        setupSlider(".slider-products")
      }, 300)
    }, [withSlider])
  }

  return (
    <section className="relative">
      <div className="container relative bg-slate-50 dark:bg-slate-900">
        <div className="grid grid-cols-1 text-center">
          <h2 className="h2 text-lg my-12 font-bold font-Montserrat">
            <Trans>ADVANTAGES.head</Trans>
          </h2>
        </div>
      </div>
        <div className="container bg-slate-50 dark:bg-slate-900">
          {withSlider && (
            <div className="slider-products mt-8">
              {products.map((product) => {
                return (
                  <ProductCard product={product} />
                )
              })}
            </div>
          )}
          {!withSlider && (
            <div className="grid grid-cols-1 md:gap-x-[60px] md:grid-cols-2 xl:grid-cols-3">
              {products.map((product) => {
                return (
                  <ProductCard product={product} />
                )
              })}
            </div>
          )}
        </div>
    </section>
  )
}

export default ProductsContainer
